import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LeaseOffer,
  firestoreGet,
  ModelTrim,
  CarModel,
  CarMake,
  Dealer,
  fixTimeStamps,
} from 'leasemojo-common';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';


interface OfferDetails {
  [ offerId: string ]: LeaseOffer
};

interface OffersState {
  offers: OfferDetails,
  loading: boolean;
  updateOfferPending: boolean;
}


const initialState: OffersState = {
  offers: {},
  loading: true,
  updateOfferPending: false,
};


const offersService = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    resetState(): OffersState {
      return { ...initialState };
    },
    setLoading(state, action: PayloadAction<boolean>): OffersState {
      return { ...state, loading: action.payload, }
    },
    setUpdateOfferPending(state, action: PayloadAction<boolean>): OffersState {
      return { ...state, updateOfferPending: action.payload, }
    },
    setOfferData(state, action: PayloadAction<LeaseOffer>): OffersState {
      if (!action.payload.id) {
        return state;
      }

      return {
        ...state,
        offers: {
          ...state.offers,
          [ action.payload.id ]: action.payload
        }
      }
    },
    setChatAllowed(state, action: PayloadAction<{ offerId: string; enabled: boolean }>): OffersState {
      if (!state.offers[ action.payload.offerId ]) {
        return state;
      }
      return {
        ...state,
        offers: {
          ...state.offers,
          [ action.payload.offerId ]: {
            ...state.offers[ action.payload.offerId ],
            chatAllowed: action.payload.enabled
          }
        }
      }
    }
  }
});

const reset = (): AppThunk => async (dispatch, getState) => {
  liveUnsubscribe && liveUnsubscribe();
  liveUnsubscribe = null;
  dispatch(offersService.actions.resetState());
}


const toggleChat = (offerId: string, enabled: boolean): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  try {
    dispatch(offersService.actions.setChatAllowed({
      offerId,
      enabled
    }));
    await firebase.firestore().collection('offers').doc(offerId).update({
      chatAllowed: enabled
    });
  }
  catch (e) {
    console.error(e);
  }
}

const setOfferAsSeen = async (offerId: string) => {
  try {
    const { firebase, FieldValue } = await getFirebase();
    await firebase.firestore().collection('offers').doc(offerId).update({
      userSeenTime: FieldValue.serverTimestamp(),
    });
  }
  catch (e) {
    console.error(e);
  }
}


let liveUnsubscribe: any;

const loadOffer = (id: string): AppThunk => async (dispatch, getState) => {
  const state = getState().offers;
  if (!state.offers[ id ]) {
    dispatch(offersService.actions.setLoading(true));
  }

  const { firebase } = await getFirebase();

  try {
    const ref = firebase.firestore().collection('offers').doc(id);
    if (liveUnsubscribe) {
      liveUnsubscribe();
      liveUnsubscribe = null;
    }

    liveUnsubscribe = ref.onSnapshot({
      next: async (snapshot) => {
        let offer = snapshot.data() as LeaseOffer;
        offer.id = snapshot.id;

        if (offer.userSeenTime === null && !snapshot.metadata.hasPendingWrites) {
          await setOfferAsSeen(offer.id);
        }

        const carRef = firebase.firestore().collection('cars').doc(offer.car);
        const modelRef = carRef.collection('models').doc(offer.model);
        const trimRef = modelRef.collection('trims').doc(offer.trim);
        const dealerRef = firebase.firestore().collection('dealers').doc(offer.dealer);

        const result = await Promise.all([
          firestoreGet<CarMake>(carRef, { cache: true }),
          firestoreGet<CarModel>(modelRef, { cache: true }),
          firestoreGet<ModelTrim>(trimRef, { cache: true }),
          firestoreGet<Dealer>(dealerRef, { cache: false }),
        ])

        if (result[ 0 ] && result[ 1 ] && result[ 2 ] && result[ 3 ]) {
          offer.carData = result[ 0 ];
          offer.modelData = result[ 1 ];
          offer.trimData = result[ 2 ];
          offer.dealerData = result[ 3 ];
          offer.dealerData = fixTimeStamps(offer.dealerData);
        }

        offer = fixTimeStamps(offer);

        // offer.createTime = (offer.createTime as firebase.firestore.Timestamp).seconds * 1000;
        // offer.updateTime = offer.updateTime ? (offer.updateTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
        // offer.expireTime = (offer.expireTime as firebase.firestore.Timestamp).seconds * 1000;
        // offer.lastMessageSeenTimeAgent = offer.lastMessageSeenTimeAgent ? (offer.lastMessageSeenTimeAgent as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
        // offer.lastMessageSeenTimeUser = offer.lastMessageSeenTimeUser ? (offer.lastMessageSeenTimeUser as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
        // offer.lastMessageTime = offer.lastMessageTime ? (offer.lastMessageTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
        // offer.userSeenTime = offer.userSeenTime ? (offer.userSeenTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
        // offer.soldTime = offer.soldTime && (offer.soldTime as firebase.firestore.Timestamp).seconds * 1000;

        dispatch(offersService.actions.setOfferData(offer));
        dispatch(offersService.actions.setLoading(false));
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  catch (e) {
    console.error(e);
  }
}

export const actions = {
  loadOffer,
  toggleChat,
  reset,
};

export default offersService.reducer;