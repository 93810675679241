import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, TextField } from "@material-ui/core";
import ProgressButton from '../ButtonLoading';
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";

import { actions } from '../../services/reAuth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      bottom: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
      },
    }
  ),
);

const ReAuthPhoneConfirm: React.FC = () => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state.reAuth);
  const dispatch = useDispatch();
  const [ code, setCode ] = useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(actions.reAuthWithPhone(code));
  };



  return (
    <div className={classes.root}>
      <form onSubmit={onSubmit}>
        <Typography paragraph variant="body2">We sent you a confirmation code. Please enter it here.</Typography>
        <TextField
          label="Confirmation code"
          variant="outlined"
          fullWidth
          error={state.phoneConfirmError}
          helperText={state.phoneConfirmError ? 'Invalid code' : null}
          value={code}
          onChange={onChange}
          disabled={state.loading && state.method === 'phone'}
        />

        <div className={classes.bottom}>
          <ProgressButton
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
            loading={state.loading && state.method === 'phone'}
          >
            Confirm
          </ProgressButton>
        </div>
      </form>
    </div>
  );
};

export default ReAuthPhoneConfirm;