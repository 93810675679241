import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import IconPhone from '@material-ui/icons/Phone';

import { AuthMethod } from "../types";
import Button from './ButtonLoading';

import GoogleIcon from '../icons/google.svg';
import FacebookIcon from '../icons/facebook.svg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      button: {
        // width: 300,
        width: '100%',

      },
      phone: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        }
      },
      facebook: {
        backgroundColor: '#3b5999',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#304b84',
        }
      },
      google: {
        backgroundColor: '#ca3f23',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#ad351c',
        }
      },
      socialIcon: {
        width: 18,
        height: 18,
        fill: '#fff',
      },
    }
  ),
);

interface Props {
  loading?: boolean;
  type: AuthMethod;
  onClick: () => void;
}

const AuthButton: React.FC<Props> = ({ loading, type, onClick }) => {
  const classes = useStyles();


  const buildPhone = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.phone)}
      startIcon={<IconPhone />}
      onClick={onClick}
      loading={Boolean(loading)}
    >
      Continue with Phone
    </Button>
  )

  const buildFacebook = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.facebook)}
      loading={Boolean(loading)}
      onClick={onClick}
      startIcon={<FacebookIcon className={classes.socialIcon}/>}
    >
      Continue with Facebook
    </Button>
  )

  const buildGoogle = () => (
    <Button
      size="large"
      variant="contained"
      className={clsx(classes.button, classes.google)}
      loading={Boolean(loading)}
      onClick={onClick}
      startIcon={<GoogleIcon className={classes.socialIcon}/>}
    >
      Continue with Google
    </Button>
  )

  if (type === 'phone') {
    return buildPhone();
  }
  else if (type === 'facebook.com') {
    return buildFacebook();
  }
  else if (type === 'google.com') {
    return buildGoogle();
  }
  
  return null;
};

export default AuthButton;