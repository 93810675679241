import { getFirebase } from './firebase';



export const trackScreenView = (screenName: string) => {
  trackEvent('screen_view', {
    screen_name: screenName
  });
}

export const trackPageView = () => {
  trackEvent('page_view');
}

export const trackEvent = async (eventName: string, params?: { [ key: string ]: any }) => {
  // console.log('trackEvent', eventName, params);
  const { analytics } = await getFirebase();
  
  analytics.logEvent(eventName, params);
}