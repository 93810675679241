import 'typeface-roboto';
import React from 'react';
import queryString from 'query-string';
import Notifications from './src/components/Notifications';
import { DialogConsumer } from './src/components/DialogProvider';
import ReAuthModal from './src/components/ReAuthModal/ReAuthModal';

import withState from './withState';

import { trackPageView } from './src/analytics';

export const wrapRootElement = withState;

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (prevRouterProps.location.pathname !== routerProps.location.pathname) {
    return true;
  }

  const argsToScroll = ['step'];

  const prevParams = queryString.parse(prevRouterProps.location.search);
  const params = queryString.parse(routerProps.location.search);

  for (let key in params) {
    if (
      (typeof prevParams[key] === 'undefined' ||
        params[key] !== prevParams[key]) &&
      argsToScroll.includes(key)
    ) {
      return true;
    }
  }
  return false;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <React.Fragment>
      {element}
      <Notifications />
      <DialogConsumer />
      <ReAuthModal />
    </React.Fragment>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    console.log('pageView', prevLocation?.pathname, location.pathname);
    trackPageView();
  }, 50);
};
