import firebase from "firebase/app"

let instance: firebase.app.App;

export const getFirebase = async () => {
  if (instance) {
    return {
      firebase: instance,
      analytics: instance.analytics(),
      FieldValue: firebase.firestore.FieldValue,
      EmailAuthProvider: firebase.auth.EmailAuthProvider,
      auth: firebase.auth,
    };
  }

  await Promise.all([
    import("firebase/auth"),
    import("firebase/firestore"),
    import("firebase/functions"),
    import("firebase/messaging"),
    import("firebase/analytics"),
  ]);

  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  };


  instance = firebase.initializeApp(firebaseConfig);

  try {
    if (process.env.FIREBASE_MESSAGING_KEY) {
      console.log('messaging.usePublicVapidKey', process.env.FIREBASE_MESSAGING_KEY);
      firebase.messaging().usePublicVapidKey(process.env.FIREBASE_MESSAGING_KEY);
    };
  }
  catch(e) {
    console.error(e);
  }

  return {
    firebase: instance,
    analytics: instance.analytics(),
    FieldValue: firebase.firestore.FieldValue,
    EmailAuthProvider: firebase.auth.EmailAuthProvider,
    auth: firebase.auth,
  };
}