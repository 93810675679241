import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import user, { actions as userActions } from './services/user';
import signIn from './services/signIn';
import inquiries from './services/inquiries';
import offers from './services/offers';
import chat from './services/chat';
import notifications from './services/notifications';
import reviews from './services/reviews';
import reAuth from './services/reAuth';
import support from './services/support';

const rootReducer = combineReducers({
  user,
  signIn,
  inquiries,
  offers,
  chat,
  notifications,
  reviews,
  reAuth,
  support,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;


const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./store', () => {
    store.replaceReducer(rootReducer);
  });
}

if (typeof window !== 'undefined') {
  store.dispatch(userActions.init() as any);
}

export type AppDispatch = typeof store.dispatch;
export default store;