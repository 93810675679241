import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';
import { AuthMethod } from '../types';


interface ReAuthState {
  loading: boolean;
  method: AuthMethod;
  open: boolean;
  phoenConfirm: boolean;
  phoneConfirmError: boolean;
}


const initialState: ReAuthState = {
  loading: false,
  method: 'phone',
  open: false,
  phoenConfirm: false,
  phoneConfirmError: false,
};


let _callback: (() => void) | null = null;
let _verificationId: string | null = null;

const reAuthService = createSlice({
  name: 'reAuth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, loading: action.payload, }
    },
    setOpen(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, open: action.payload, }
    },
    setPhoneConfirm(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, phoenConfirm: action.payload, }
    },
    setPhoneConfirmError(state, action: PayloadAction<boolean>): ReAuthState {
      return { ...state, phoneConfirmError: action.payload, }
    },
    setMethod(state, action: PayloadAction<AuthMethod>): ReAuthState {
      return { ...state, method: action.payload, }
    },
  }
});

const close = (): AppThunk => async (dispatch, getState) => {
  _callback = null;
  dispatch(reAuthService.actions.setOpen(false));
}

const open = (callback: () => void): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  try {
    const user = getState().user.user;
    const currentUser = firebase.auth().currentUser;

    if (!user || !currentUser) {
      return;
    }

    const method = currentUser.providerData[ 0 ]?.providerId;
    if (!method) {
      return;
    }

    _callback = callback;

    dispatch(reAuthService.actions.setMethod(method as AuthMethod));
    dispatch(reAuthService.actions.setPhoneConfirm(false));
    dispatch(reAuthService.actions.setOpen(true));
  }
  catch (e) {
    console.error(e);
  }
}

const sendPhoneVerification = (): AppThunk => async (dispatch, getState) => {
  try {
    const { auth } = await getFirebase();
    const user = getState().user.user;

    if (!user || !user.phone) {
      return;
    }

    dispatch(reAuthService.actions.setPhoneConfirmError(false));
    dispatch(reAuthService.actions.setLoading(true));

    const appVerifier = new auth.RecaptchaVerifier('recaptcha', { size: 'invisible' });
    _verificationId = await new auth.PhoneAuthProvider().verifyPhoneNumber(user.phone, appVerifier);
    dispatch(reAuthService.actions.setPhoneConfirm(true));
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(reAuthService.actions.setLoading(false));
  }
}

const reAuthWithPhone = (verificationCode: string): AppThunk => async (dispatch, getState) => {
  try {
    const { auth, firebase } = await getFirebase();
    const user = getState().user.user;

    if (!user || !user.phone) {
      return;
    }

    if (!_verificationId) {
      dispatch(reAuthService.actions.setPhoneConfirm(false));
      return;
    }

    dispatch(reAuthService.actions.setPhoneConfirmError(false));
    dispatch(reAuthService.actions.setLoading(true));

    const credential = auth.PhoneAuthProvider.credential(_verificationId, verificationCode);
    const result = await firebase.auth().signInWithCredential(credential);
    if (result.user) {
      dispatch(reAuthService.actions.setOpen(false));
      if (_callback) {
        console.log('callback calling');
        _callback && _callback();
      }
      else {
        console.log('callback is not SET');
      }
    }

  }
  catch (e) {
    dispatch(reAuthService.actions.setPhoneConfirmError(true));
    console.error(e);
  }
  finally {
    dispatch(reAuthService.actions.setLoading(false));
  }
}



export const actions = {
  open,
  close,
  sendPhoneVerification,
  reAuthWithPhone,
};

export default reAuthService.reducer;