import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, CircularProgress } from "@material-ui/core";
import { ButtonTypeMap, ButtonProps } from "@material-ui/core/Button";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      buttonLoading: {
        position: 'relative',
      },
      indicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
      }
    }
  ),
);

interface Props extends ButtonProps {
  loading: boolean;
}

const ButtonLoading: React.FC<Props> = ({ loading, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button disabled={loading} className={classes.buttonLoading} {...rest}>
      {children}
      {loading && (
        <CircularProgress size={24} className={classes.indicator} />
      )}
    </Button>
  );
};

export default ButtonLoading;