import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Base64 } from 'js-base64';
import {
  CreditScore,
  UserData,
} from 'leasemojo-common';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import { actions as chatActions } from './chat';
import { actions as inquiryActions } from './inquiries';
import { actions as offerActions } from './offers';
import { actions as reviewActions } from './reviews';
import { actions as signInActions } from './signIn';
import { actions as reAuthActions } from './reAuth';
import { actions as notification } from './notifications';

export const fetchUserProfile = async (user: firebase.User, zip: number | null, creditScore: CreditScore | null): Promise<UserData | null> => {
  let userDoc;
  const { firebase } = await getFirebase();

  try {
    const userDocRef = firebase.firestore().collection('users').doc(user.uid);
    userDoc = await userDocRef.get();
  }
  catch (e) {
    console.log('error fetching profile, proceeding to create')
  }

  if (!userDoc || !userDoc.exists) { 
    const userData = await createUserProfile(user, zip, creditScore);
    if (!userData) {
      await firebase.auth().signOut();
      return null;
    }
    return userData;
  }
  else {
    const userData = userDoc.data() as UserData;
    userData.id = userDoc.id;
    userData.createTime = (userData.createTime as firebase.firestore.Timestamp).seconds * 1000;
    userData.updateTime = userData.updateTime ? (userData.updateTime as firebase.firestore.Timestamp).seconds * 1000 : null;
    return userData;
  }
};

const createUserProfile = async (user: firebase.User, zip: number | null, creditScore: CreditScore | null): Promise<UserData | null> => {
  const { firebase, FieldValue } = await getFirebase();
  const [ firstName = '', lastName = '' ] = user.displayName ? user.displayName?.split(' ') : [];
  try {
    const data = {
      status: 'active',
      firstName,
      lastName,
      authProvider: user.providerData[ 0 ]?.providerId,
      phone: user.phoneNumber || '',
      email: user.email || '',
      avatar: user.photoURL || '',
      zipCode: zip,
      creditScore: creditScore,
      inquiries: 0,
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      updateTime: null,
    }
    console.log('userCreate', data);
    await firebase.firestore().collection('users').doc(user.uid).set(data);
    const userData = data as UserData & { authProvider: string };
    userData.id = user.uid;
    userData.createTime = new Date().getTime();
    return userData;
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

interface UserState {
  user: UserData | null;
  userDataLoading: boolean;
  deleteAccountLoading: boolean;
  zipCode: number | null;
  creditScore: CreditScore | null;
  pushDisabled: boolean;
  profileUpdateLoading: boolean;
}

const _savedZip = typeof window !== 'undefined' ? window.localStorage.getItem('zip') : null;
const _savedCreditScore = typeof window !== 'undefined' ? window.localStorage.getItem('creditScore') as CreditScore : null;

const initialState: UserState = {
  user: null,
  userDataLoading: true,
  deleteAccountLoading: false,
  zipCode: _savedZip ? parseInt(_savedZip) : null,
  creditScore: _savedCreditScore,
  pushDisabled: false,
  profileUpdateLoading: false,
};



const userService = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState(): UserState {
      return { ...initialState };
    },
    setDeleteAccountLoading(state, action: PayloadAction<boolean>): UserState {
      return { ...state, deleteAccountLoading: action.payload };
    },
    setPushDisabled(state, action: PayloadAction<boolean>): UserState {
      return { ...state, pushDisabled: action.payload };
    },
    setProfileUpdateLoading(state, action: PayloadAction<boolean>): UserState {
      return { ...state, profileUpdateLoading: action.payload };
    },
    setUserData(state, action: PayloadAction<UserData | null>): UserState {
      const zip = (action.payload && action.payload.zipCode) || null;
      const creditScore = (action.payload && action.payload.creditScore) || null;
      const newState = { ...state };

      if (zip) {
        newState.zipCode = zip;
      }
      if (creditScore) {
        newState.creditScore = creditScore;
      }

      return {
        ...newState,
        userDataLoading: false,
        user: action.payload
      }
    },
    setCreditScore(state, action: PayloadAction<CreditScore>): UserState {
      const newState = { ...state };

      if (newState.user) {
        newState.user = {
          ...newState.user,
          creditScore: action.payload,
        };
      }

      return {
        ...newState,
        creditScore: action.payload
      }
    },

    setZipCode(state, action: PayloadAction<number>): UserState {
      const newState = { ...state };

      if (newState.user) {
        newState.user = {
          ...newState.user,
          zipCode: action.payload,
        };
      }

      return {
        ...newState,
        zipCode: action.payload
      }
    },
  }
});

const savePushToken = (token: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  if (!user) {
    return;
  }
  const { firebase, FieldValue } = await getFirebase();
  const deviceId = Base64.encode(navigator.userAgent);
  const pushTokens = user.pushTokens ? {
    ...user.pushTokens
  } : {
    };

  pushTokens[ deviceId ] = {
    deviceId: navigator.userAgent,
    token
  }

  await firebase.firestore().collection('users').doc(user.id).update({
    pushTokens,
    updateTime: FieldValue.serverTimestamp(),
  });

  console.log('push token saved', token);
}

const removePushToken = (): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  if (!user) {
    return;
  }
  if (!user.pushTokens) {
    return;
  }
  const { firebase, FieldValue } = await getFirebase();
  const deviceId = Base64.encode(navigator.userAgent);

  const tokens = {
    ...user.pushTokens
  }

  delete tokens[ deviceId ];

  await firebase.firestore().collection('users').doc(user.id).update({
    pushTokens: tokens,
    updateTime: FieldValue.serverTimestamp(),
  });

  console.log('push token removed');
}

const requestPushPermission = (): AppThunk => async (dispatch, getState) => {
  if ('Notification' in window) {
    const { firebase } = await getFirebase();
    const messaging = firebase.messaging();
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        messaging.getToken().then((refreshedToken) => {
          dispatch(savePushToken(refreshedToken));
        }).catch((err) => {
          dispatch(userService.actions.setPushDisabled(true));
          console.error('Unable to retrieve refreshed token ', err);
        });
      } else {
        dispatch(userService.actions.setPushDisabled(true));
        console.error('Unable to get permission to notify.');
      }
    });
  }
}

const initPushNotifications = (): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase } = await getFirebase();
    const messaging = firebase.messaging();
  
  
    if ('Notification' in window && Notification.permission === 'denied') {
      dispatch(userService.actions.setPushDisabled(true));
    }
  
    if ('Notification' in window && Notification.permission === 'granted') {
      dispatch(requestPushPermission());
    }
  
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        console.log('Token refreshed.', refreshedToken);
        dispatch(savePushToken(refreshedToken));
      }).catch((err) => {
        console.error('Unable to retrieve refreshed token ', err);
      });
    });
  }
  catch(e) {
    console.error(e);
  }
}

const init = (): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();

  firebase.auth().onAuthStateChanged(async (fUser) => {
    const signIn = getState().signIn;

    if (fUser) {
      if (signIn.inProgress) {
        return;
      }

      const state = getState().user.user;
      const user = await fetchUserProfile(fUser, state?.zipCode || null, state?.creditScore || null);
      if (user === null) {
        dispatch(signOut());
      }
      else {
        dispatch(onLoginDone(user));
      }
    } else {
      dispatch(userService.actions.setUserData(null));
    }
  });
}

const onLoginDone = (user: UserData): AppThunk => async (dispatch, getState) => {
  dispatch(userService.actions.setUserData(user));
  dispatch(inquiryActions.loadMyInquiries());
  dispatch(chatActions.init());
  dispatch(initPushNotifications());
}

const signOut = (): AppThunk => async dispatch => {
  const { firebase } = await getFirebase();

  await dispatch(removePushToken());

  await firebase.auth().signOut();

  dispatch(signInActions.reset());
  dispatch(userService.actions.resetState());

  dispatch(chatActions.reset());
  dispatch(inquiryActions.reset());
  dispatch(offerActions.reset());
  dispatch(reviewActions.reset());

}

const saveZipCode = (zip: number): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();
  const user = getState().user.user;

  window.localStorage.setItem('zip', zip.toString());
  dispatch(userService.actions.setZipCode(zip));
  
  if (user) {
    await firebase.firestore().collection('users').doc(user.id).update({
      zipCode: zip,
      updateTime: FieldValue.serverTimestamp(),
    });
  }
  dispatch(userService.actions.setZipCode(zip));
}

const saveCreditScore = (creditScore: CreditScore): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();

  const user = getState().user.user;

  window.localStorage.setItem('creditScore', creditScore);
  if (user) {
    await firebase.firestore().collection('users').doc(user.id).update({
      creditScore,
      updateTime: FieldValue.serverTimestamp(),
    });
  }
  dispatch(userService.actions.setCreditScore(creditScore));
}

interface UpdateProfileArgs {
  firstName: string;
  lastName: string;
  email: string;
}

const updateProfile = (args: UpdateProfileArgs): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase, FieldValue } = await getFirebase();
    const user = getState().user.user;
    const currentUser = firebase.auth().currentUser;
    if (!user || !currentUser) {
      return;
    }

    dispatch(userService.actions.setProfileUpdateLoading(true));
    if (args.email !== user.email) {
      await currentUser.updateEmail(args.email);
    }

    await firebase.firestore().collection('users').doc(user.id).update({
      updateTime: FieldValue.serverTimestamp(),
      ...args
    });

    dispatch(userService.actions.setUserData({
      ...user,
      ...args,
    }))
    dispatch(notification.show({ message: 'Profile updated' }));
  }
  catch (e) {
    if (e.code === 'auth/requires-recent-login') {
      const callback = () => {
        dispatch(updateProfile(args));
      }
      dispatch(reAuthActions.open(callback));
    }
    else {
      dispatch(notification.show({ message: e.message, variant: 'error', autoHideDuration: 5000 }));
    }
    console.error(e);
  }
  finally {
    dispatch(userService.actions.setProfileUpdateLoading(false));
  }
}

const deleteAccount = (): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase } = await getFirebase();
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return;
    }
    dispatch(userService.actions.setDeleteAccountLoading(true));

    await currentUser.delete();
  }
  catch (e) {
    if (e.code === 'auth/requires-recent-login') {
      const callback = () => {
        dispatch(deleteAccount());
      }
      dispatch(reAuthActions.open(callback));
    }
    else {
      dispatch(notification.show({ message: e.message, variant: 'error', autoHideDuration: 5000 }));
    }
    console.error(e);
  }
  finally {
    dispatch(userService.actions.setDeleteAccountLoading(false));
  }
}


export const actions = {
  init,
  signOut,
  saveZipCode,
  saveCreditScore,
  onLoginDone,
  setUserData: userService.actions.setUserData,
  requestPushPermission,
  initPushNotifications,
  updateProfile,
  deleteAccount,
};

export default userService.reducer;