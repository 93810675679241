// import { pink, indigo } from "@material-ui/core/colors"

import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2e3842'
    },
    secondary: {
      main: '#00d1b2'
      // main: '#ed4933',
    },
    // contrastThreshold: 2,
  },
})

export default theme
