import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  InquiryData,
  firestoreLookup,
  CarMake,
  CarModel,
  ModelTrim,
  Dealer,
  fixTimeStamps,
} from 'leasemojo-common';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';

import orderBy from 'lodash/orderBy';
import { InquirySaveArgs } from '../types';
import { navigate } from 'gatsby';
import { firestore } from 'firebase';
import { trackEvent } from '../analytics';

interface ProcessInquiryResult {
  inquiry: InquiryData;
  type: firebase.firestore.DocumentChangeType;
  oldIndex: number;
  index: number;
}

const processInquiries = async (snapshot: firebase.firestore.QuerySnapshot): Promise<ProcessInquiryResult[]> => {
  const { firebase } = await getFirebase();
  const carRefs: firebase.firestore.DocumentReference[] = [];
  const modelRefs: firebase.firestore.DocumentReference[] = [];
  const trimRefs: firebase.firestore.DocumentReference[] = [];
  const dealerRefs: firebase.firestore.DocumentReference[] = [];

  const inquiries: ProcessInquiryResult[] = [];

  snapshot.docChanges().forEach(item => {
    const data = fixTimeStamps(item.doc.data()) as InquiryData;
    data.id = item.doc.id;
    const carRef = firebase.firestore().collection('cars').doc(data.car);
    const modelRef = carRef.collection('models').doc(data.model);
    const trimRef = modelRef.collection('trims').doc(data.trim);

    carRefs.push(carRef);
    modelRefs.push(modelRef);
    trimRefs.push(trimRef);

    for (let dealerId in data.offers) {
      dealerRefs.push(firebase.firestore().collection('dealers').doc(dealerId));
      trimRefs.push(modelRef.collection('trims').doc(data.offers[ dealerId ].trim));
    }

    for (let key in data.offers) {
      data.offers[ key ] = fixTimeStamps(data.offers[ key ]);
    }

    inquiries.push({
      inquiry: data,
      type: item.type,
      oldIndex: item.oldIndex,
      index: item.newIndex,
    });
  });

  const cars = firestoreLookup<CarMake>(carRefs, { cache: true });
  const models = firestoreLookup<CarModel>(modelRefs, { cache: true });
  const trims = firestoreLookup<ModelTrim>(trimRefs, { cache: true });
  const dealers = firestoreLookup<Dealer>(dealerRefs, { cache: true, cacheExpire: '6h' });

  const inquiryData = await Promise.all([ cars, models, trims, dealers ]);

  return inquiries.map(item => {
    item.inquiry.carData = inquiryData[ 0 ][ item.inquiry.car ];
    item.inquiry.modelData = inquiryData[ 1 ][ item.inquiry.model ];
    item.inquiry.trimData = inquiryData[ 2 ][ item.inquiry.trim ];

    for (let dealerId in item.inquiry.offers) {
      item.inquiry.offers[ dealerId ].trimData = inquiryData[ 2 ][ item.inquiry.offers[ dealerId ].trim ];
      const dealerData = fixTimeStamps(inquiryData[ 3 ][ item.inquiry.offers[ dealerId ].dealer ]);
      item.inquiry.offers[ dealerId ].dealerData = dealerData;
    }
    return item;
  });
}


export const saveInquiryOffline = (data: InquirySaveArgs) => {
  window.localStorage.setItem('inquiry', JSON.stringify(data));
};

export const getSavedInquiry = () => {
  const json = window.localStorage.getItem('inquiry');
  if (json) {
    return JSON.parse(json) as InquirySaveArgs;
  }
  return null;
};

export const deleteSavedInquiry = () => {
  window.localStorage.removeItem('inquiry');
}



interface InquiriesState {
  inquiries: InquiryData[];
  loading: boolean;
  submitInquiryLoading: boolean;
}

const initialState: InquiriesState = {
  inquiries: [],
  loading: true,
  submitInquiryLoading: false,
};


let unsubscribe: any;


const inquiriesService = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
    resetState(): InquiriesState {
      return { ...initialState };
    },
    setLoading(state, action: PayloadAction<boolean>): InquiriesState {
      return { ...state, loading: action.payload, }
    },
    setSubmitInquiryLoading(state, action: PayloadAction<boolean>): InquiriesState {
      return { ...state, submitInquiryLoading: action.payload, }
    },
    addInquiry(state, action: PayloadAction<InquiryData>): InquiriesState {
      const newList = orderBy([
        action.payload,
        ...state.inquiries,
      ], 'createTime', 'desc');

      return {
        ...state,
        inquiries: newList
      }
    },
    updateInquiry(state, action: PayloadAction<{ index: number, inquiry: InquiryData }>): InquiriesState {
      const newList = [
        ...state.inquiries
      ];

      newList[ action.payload.index ] = action.payload.inquiry;

      return {
        ...state,
        inquiries: newList,
      }
    },
    removeInquiry(state, action: PayloadAction<string>): InquiriesState {
      const newList = state.inquiries.filter(item => item.id !== action.payload);

      return {
        ...state,
        inquiries: newList,
      }
    },
  }
});

const reset = (): AppThunk => async (dispatch, getState) => {
  unsubscribe && unsubscribe();
  unsubscribe = null;
  dispatch(inquiriesService.actions.resetState());
}

const loadMyInquiries = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  if (!state.user.user || state.inquiries.inquiries.length > 0) {
    return;
  }

  const { firebase } = await getFirebase();

  unsubscribe = firebase.firestore().collection('inquiries')
    .where('user', '==', state.user.user.id)
    .where('status', 'in', ['active', 'closed'])
    .orderBy('createTime', 'desc')
    .onSnapshot({
      next: async (snapshot) => {
        const isInitial = getState().inquiries.loading;
        if (isInitial) {
          dispatch(inquiriesService.actions.setLoading(false));
        }

        const result = await processInquiries(snapshot);
        result.forEach(item => {
          if (item.type === 'added') {
            dispatch(inquiriesService.actions.addInquiry(item.inquiry));
          }
          else if (item.type === 'modified') {
            dispatch(inquiriesService.actions.updateInquiry(item));
          }
          else if (item.type === 'removed' && item.inquiry.id) {
            dispatch(inquiriesService.actions.removeInquiry(item.inquiry.id));
          }
        });
      },
      error: (err) => {
        console.log(err);
      }
    });
}


const submitInquiry = (data: InquirySaveArgs): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();

  const user = getState().user.user;
  if (!user) {
    trackEvent('saveInquiryOffline');
    saveInquiryOffline(data);
    navigate('/app/sign-in');
  } else {
    try {
      dispatch(inquiriesService.actions.setSubmitInquiryLoading(true));
      const inquiry: InquiryData = {
        status: 'active',
        user: user.id,
        createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        offers: {},
        ...data
      };
      console.log('Subnit inquiry', inquiry);
      const firstInquiry = getState().inquiries.inquiries.length === 0;
      trackEvent('submitInquiry');
      await firebase.firestore().collection('inquiries').add(inquiry);

      if (firstInquiry) {
        navigate('/app/my-offers?permission=1')
      }
      else {
        navigate('/app/my-offers')
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      dispatch(inquiriesService.actions.setSubmitInquiryLoading(false));
    }
  }
}

const deleteInquiry = (id: string): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();
  const user = getState().user.user;
  if (!user) {
    return;
  }


  await firebase.firestore().collection('inquiries').doc(id).update({
    status: 'removed',
    removeTime: FieldValue.serverTimestamp(),
  });
}

const setWinningOffer = (args: { inquiryId: string, offerId: string }): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();
  const user = getState().user.user;
  if (!user) {
    return;
  }


  await firebase.firestore().collection('inquiries').doc(args.inquiryId).update({
    status: 'closed',
    closeTime: FieldValue.serverTimestamp(),
    winningOffer: args.offerId,
  });
}


export const actions = {
  loadMyInquiries,
  submitInquiry,
  deleteInquiry,
  reset,
  setWinningOffer,
};

export default inquiriesService.reducer;