import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';
import { ContactRequest } from 'leasemojo-common';

interface SupportState {
  loading: boolean;
}



const initialState: SupportState = {
  loading: false,
};


const service = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): SupportState {
      return { ...state, loading: action.payload, }
    },
  }
});

interface SendMessageArgs {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const sendMessage = (args: SendMessageArgs): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();
  const user = getState().user.user;

  try {
    dispatch(service.actions.setLoading(true));

    const doc: Partial<ContactRequest> = {
      status: 'open',
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      user: user ? user.id : null,
      name: args.name,
      email: args.email,
      phone: args.phone,
      message: args.message,
    };

    await firebase.firestore().collection('contact_requests').add(doc);
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

export const actions = {
  sendMessage
};

export default service.reducer;