import React from 'react';
import { globalHistory } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import { SnackbarProvider } from 'notistack';

import { Provider } from 'react-redux';
import store from './src/store';
import { DialogProvider } from './src/components/DialogProvider';

export default ({ element }) => {
  return (
    <QueryParamProvider reachHistory={globalHistory}>
      <SnackbarProvider maxSnack={3}>
        <Provider store={store}>
          <DialogProvider>{element}</DialogProvider>
        </Provider>
      </SnackbarProvider>
    </QueryParamProvider>
  );
};
