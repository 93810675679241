import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getFirebase } from '../firebase';
import { AppThunk } from '../store';
import { navigate } from 'gatsby';
import { fetchUserProfile, actions as userActions } from './user';
import { getSavedInquiry, actions as inquiryActions, deleteSavedInquiry } from './inquiries';
import { AuthMethod } from '../types';


declare global {
  interface Window {
    grecaptcha: any
  }
}

let recaptchaVerifier: firebase.auth.RecaptchaVerifier;
var confirmationResult: firebase.auth.ConfirmationResult;


interface SignInState {
  codeSendLoading: boolean;
  codeConfirmLoading: boolean;
  profileCompleteLoading: boolean;
  codeSendError: string | null;
  codeConfirmError: string | null;
  step: number;
  phone: string;
  inProgress: AuthMethod | null;
  error: string | null;
}

const initialState: SignInState = {
  codeSendLoading: false,
  codeConfirmLoading: false,
  profileCompleteLoading: false,
  inProgress: null,
  codeSendError: null,
  codeConfirmError: null,
  step: 0,
  phone: '',
  error: null,
}

const signInService = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    resetState(): SignInState {
      return { ...initialState };
    },
    setCodeSendLoading(state, action: PayloadAction<boolean>): SignInState {
      return { ...state, codeSendLoading: action.payload, }
    },
    setInProgress(state, action: PayloadAction<AuthMethod | null>): SignInState {
      return { ...state, inProgress: action.payload, }
    },
    setCodeConfirmLoading(state, action: PayloadAction<boolean>): SignInState {
      return { ...state, codeConfirmLoading: action.payload, }
    },
    setProfileCompleteLoading(state, action: PayloadAction<boolean>): SignInState {
      return { ...state, profileCompleteLoading: action.payload, }
    },
    setCodeSendError(state, action: PayloadAction<string | null>): SignInState {
      return { ...state, codeSendError: action.payload, }
    },
    setCodeConfirmError(state, action: PayloadAction<string | null>): SignInState {
      return { ...state, codeConfirmError: action.payload, }
    },
    setStep(state, action: PayloadAction<number>): SignInState {
      return { ...state, step: action.payload, }
    },
    setPhone(state, action: PayloadAction<string>): SignInState {
      return { ...state, phone: action.payload, }
    },
    setError(state, action: PayloadAction<string | null>): SignInState {
      return { ...state, error: action.payload, }
    },
  }
});

const signInPhone = (): AppThunk => async (dispatch, getState) => {
  dispatch(signInService.actions.setStep(1));
}

const signInFacebook = (): AppThunk => async (dispatch, getState) => {
  dispatch(signInService.actions.setInProgress('facebook'));
  try {
    const { firebase, auth } = await getFirebase();
    var provider = new auth.FacebookAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    await dispatch(processLogin(result));
    await result.user?.sendEmailVerification();
  }
  catch (e) {
    if (e.code === 'auth/account-exists-with-different-credential') {
      dispatch(signInService.actions.setError(`Looks like you have previously signed up with ${e.email} using other method, please try signing in with Google.`));
    }
    else {
      dispatch(signInService.actions.setError(e.message));
    }
    console.error(e);
  }
  finally {
    dispatch(signInService.actions.setInProgress(null));
  }
}

const signInGoogle = (): AppThunk => async (dispatch, getState) => {
  dispatch(signInService.actions.setInProgress('google'));
  try {
    const { firebase, auth } = await getFirebase();
    var provider = new auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    await dispatch(processLogin(result));
  }
  catch (e) {
    dispatch(signInService.actions.setError(e.message));
    console.error(e);
  }
  finally {
    dispatch(signInService.actions.setInProgress(null));
  }
}

const processLogin = (result: firebase.auth.UserCredential): AppThunk => async (dispatch, getState) => {
  if (result.user) {
    const state = getState().user;
    const user = await fetchUserProfile(result.user, state.zipCode, state.creditScore);;

    if (!user) {
      dispatch(userActions.signOut());
      dispatch(signInService.actions.setError('Error during sign-in, please try again.'));
    }
    else {
      dispatch(userActions.onLoginDone(user));
      dispatch(finishSignIn());
    }
    console.log('process login done');
  }
}

const phoneCodeSend = (recaptchaContainerId: string): AppThunk => async (dispatch, getState) => {
  const { firebase, auth } = await getFirebase();
  const phone = getState().signIn.phone.replace(/ /g, '').replace(/_/g, '');
  if (phone.length !== 12) {
    dispatch(signInService.actions.setCodeSendError('Invalid phone'));
    return;
  }
  dispatch(signInService.actions.setInProgress('phone'));

  dispatch(signInService.actions.setPhone(phone));

  dispatch(signInService.actions.setCodeSendLoading(true));
  dispatch(signInService.actions.setCodeSendError(null));

  recaptchaVerifier = new auth.RecaptchaVerifier(recaptchaContainerId, {
    'size': 'invisible',
    'callback': async () => {
      try {
        confirmationResult = await firebase.auth().signInWithPhoneNumber(phone, recaptchaVerifier);
        const widgetId = await recaptchaVerifier.render();
        window.grecaptcha.reset(widgetId);
        dispatch(signInService.actions.setStep(2));
      }
      catch (e) {
        console.error(e);
        dispatch(signInService.actions.setCodeSendError('Error sending code'));
      }
      finally {
        dispatch(signInService.actions.setInProgress(null));
        dispatch(signInService.actions.setCodeSendLoading(false));
      }
    }
  });
  recaptchaVerifier.verify();
}

const phoneCodeConfirm = (code: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(signInService.actions.setInProgress('phone'));
    dispatch(signInService.actions.setCodeConfirmLoading(true));
    dispatch(signInService.actions.setCodeConfirmError(null));

    const state = getState().user;
    const result = await confirmationResult.confirm(code);

    if (result.user) {
      const user = await fetchUserProfile(result.user, state.zipCode, state.creditScore);

      if (!user) {
        dispatch(userActions.signOut());
        dispatch(signInService.actions.setCodeConfirmError('Error during sign-in'));
      }
      else {
        dispatch(userActions.onLoginDone(user));
        if (!user.firstName || !user.lastName) {
          dispatch(signInService.actions.setInProgress(null));
          dispatch(signInService.actions.setStep(3));
        }
        else {
          dispatch(finishSignIn());
        }
      }
    }
  }
  catch (e) {
    dispatch(signInService.actions.setCodeConfirmError('Invalid code'));
  }
  finally {
    dispatch(signInService.actions.setCodeConfirmLoading(false));
    dispatch(signInService.actions.setInProgress(null));
  }
}

interface CompleteProfileArgs {
  firstName: string;
  lastName: string;
}

const completeProfile = (args: CompleteProfileArgs): AppThunk => async (dispatch, getState) => {
  try {
    if (!args.firstName || !args.firstName) {
      return;
    }

    const { firebase, FieldValue } = await getFirebase();
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return;
    }
    const state = getState().user.user;
    const user = await fetchUserProfile(currentUser, state?.zipCode || null, state?.creditScore || null);
    if (!user) {
      return;
    }

    dispatch(signInService.actions.setProfileCompleteLoading(true));

    await firebase.firestore().collection('users').doc(currentUser.uid).update({
      firstName: args.firstName,
      lastName: args.lastName,
      updateTime: FieldValue.serverTimestamp(),
    });

    dispatch(userActions.setUserData({
      ...user,
      firstName: args.firstName,
      lastName: args.lastName,
    }));
    dispatch(finishSignIn());
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(signInService.actions.setProfileCompleteLoading(false));
  }
}

const finishSignIn = (): AppThunk => async (dispatch, getState) => {
  const savedInquiry = getSavedInquiry();
  if (savedInquiry) {
    deleteSavedInquiry();
    dispatch(inquiryActions.submitInquiry(savedInquiry));
    dispatch(signInService.actions.setInProgress(null));
    navigate('/app/my-offers');
  }
  else {
    navigate('/app/my-offers');
  }
}

export const actions = {
  phoneCodeSend,
  phoneCodeConfirm,
  completeProfile,
  setPhone: signInService.actions.setPhone,
  setStep: signInService.actions.setStep,
  signInPhone,
  signInFacebook,
  signInGoogle,
  reset: signInService.actions.resetState,
}




export default signInService.reducer;