// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-sold-car-tsx": () => import("./../src/templates/sold-car.tsx" /* webpackChunkName: "component---src-templates-sold-car-tsx" */),
  "component---src-templates-list-cars-tsx": () => import("./../src/templates/list-cars.tsx" /* webpackChunkName: "component---src-templates-list-cars-tsx" */),
  "component---src-templates-list-models-tsx": () => import("./../src/templates/list-models.tsx" /* webpackChunkName: "component---src-templates-list-models-tsx" */),
  "component---src-templates-list-trims-tsx": () => import("./../src/templates/list-trims.tsx" /* webpackChunkName: "component---src-templates-list-trims-tsx" */),
  "component---src-templates-model-config-tsx": () => import("./../src/templates/model-config.tsx" /* webpackChunkName: "component---src-templates-model-config-tsx" */),
  "component---src-templates-car-specs-tsx": () => import("./../src/templates/car-specs.tsx" /* webpackChunkName: "component---src-templates-car-specs-tsx" */),
  "component---src-templates-model-config-summary-tsx": () => import("./../src/templates/model-config-summary.tsx" /* webpackChunkName: "component---src-templates-model-config-summary-tsx" */),
  "component---src-pages-app-tsx": () => import("./../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-cars-tsx": () => import("./../src/pages/my-cars.tsx" /* webpackChunkName: "component---src-pages-my-cars-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

