import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';
import { ChatData, ChatMessage } from 'leasemojo-common';

import { actions as notifications } from './notifications';

interface ChatState {
  chats: {
    [ id: string ]: ChatData
  },
  loading: boolean;
  currentChat: string | null;
}



const initialState: ChatState = {
  chats: {},
  loading: false,
  currentChat: null,
};


const chatService = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetState(): ChatState {
      return { ...initialState };
    },
    setLoading(state, action: PayloadAction<boolean>): ChatState {
      return { ...state, loading: action.payload, }
    },
    setCurrentChat(state, action: PayloadAction<string | null>): ChatState {
      return { ...state, currentChat: action.payload, }
    },
    setMessages(state, action: PayloadAction<{ offerId: string, messages: ChatMessage[] }>): ChatState {
      return {
        ...state,
        chats: {
          ...state.chats,
          [ action.payload.offerId ]: {
            offerId: action.payload.offerId,
            messages: action.payload.messages,
          }
        }
      }
    },
    addMessage(state, action: PayloadAction<ChatMessage>): ChatState {
      const offerId = action.payload.offer;
      const current = state.chats[ offerId ] ? {
        ...state.chats[ offerId ]
      } : {
          offerId,
          messages: []
        };

      return {
        ...state,
        chats: {
          ...state.chats,
          [ offerId ]: {
            ...current,
            messages: [
              ...current.messages,
              action.payload,
            ]
          }
        }
      }
    },
  }
});


let isInitial = true;
let unsubscribe: any;

const reset = (): AppThunk => async (dispatch, getState) => {
  unsubscribe && unsubscribe();
  unsubscribe = null;
  isInitial = true;
  dispatch(chatService.actions.resetState());
}

const init = (): AppThunk => async (dispatch, getState) => {
  const { firebase } = await getFirebase();
  const user = getState().user.user;
  if (!user) {
    return;
  }

  try {
    unsubscribe = firebase.firestore()
      .collection('chat')
      .where('user', '==', user.id)
      .orderBy('createTime', 'desc')
      .limit(5)
      .onSnapshot({
        next: (snapshot) => {
          const chat = getState().chat;
          if (isInitial) {
            isInitial = false;
            return;
          }

          snapshot.docChanges().forEach(item => {
            if (item.type !== 'added') {
              return;
            }

            const message = item.doc.data() as ChatMessage;
            message.id = item.doc.id;
            message.createTime = message.createTime ? (message.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
            if (chat.chats[ message.offer ]) {
              dispatch(chatService.actions.addMessage(message));
            }
            if (chat.currentChat !== message.offer) {
              dispatch(notifications.show({ message: 'Dealer: ' + message.message, targetUrl: `/app/my-offers/${message.offer}?chat=1`, autoHideDuration: 5000 }));
            }
          })
        },
        error: (err) => {
          console.error(err);
        }
      });
  }
  catch (e) {
    console.error(e);
  }
}

const loadMessages = (offerId: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;
  const chat = getState().chat;

  if (!user || chat.chats[ offerId ]) {
    return;
  }

  try {
    const { firebase } = await getFirebase();
    dispatch(chatService.actions.setLoading(true));
    const result = await firebase.firestore()
      .collection('chat')
      .where('user', '==', user.id)
      .where('offer', '==', offerId)
      .orderBy('createTime', 'desc')
      .limit(100)
      .get();


    const messages: ChatMessage[] = [];

    result.docs.forEach(doc => {
      const message = doc.data() as ChatMessage;
      message.id = doc.id;
      message.createTime = message.createTime ? (message.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      messages.unshift(message);
    });

    dispatch(chatService.actions.setMessages({
      offerId,
      messages
    }));
    dispatch(chatService.actions.setLoading(false));
  }
  catch (e) {
    console.error(e);
  }
}

const resetUnseenMessages = (offerId: string): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase, FieldValue } = await getFirebase();
    await firebase.firestore().collection('offers').doc(offerId).update({
      unseenMessagesUser: 0,
      lastMessageSeenTimeUser: FieldValue.serverTimestamp(),
    });
  }
  catch (e) {
    console.error(e);
  }
}

const sendMessage = (offerId: string, message: string): AppThunk => async (dispatch, getState) => {
  try {
    const { firebase, FieldValue } = await getFirebase();
    const user = getState().user.user;
    const offer = getState().offers.offers[ offerId ];

    if (!user || !offer) {
      return;
    }

    const doc: Partial<ChatMessage> = {
      createTime: FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      from: user.id,
      to: offer.agent,
      offer: offer.id || '',
      inquiry: offer.inquiry,
      user: user.id,
      agent: offer.agent,
      dealer: offer.dealer,
      message,
    }
    await firebase.firestore().collection('chat').add(doc);
  }
  catch (e) {
    console.error(e);
  }
}

export const actions = {
  init,
  sendMessage,
  loadMessages,
  resetUnseenMessages,
  setCurrentChat: chatService.actions.setCurrentChat,
  reset,
};

export default chatService.reducer;