import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DealerReview, fixTimeStamps, UserProfilePublic, attachUsers,
} from 'leasemojo-common';

import { AppThunk } from '../store';
import { getFirebase } from '../firebase';

import { actions as notification } from './notifications';



interface ReviewsState {
  loading: boolean;
  submitPending: boolean;
  cache: {
    [ dealerId: string ]: DealerReview
  },
  listCache: {
    [ dealerId: string ]: DealerReview[],
  },
}


const initialState: ReviewsState = {
  loading: false,
  submitPending: false,
  cache: {},
  listCache: {},
};


const reviewService = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    resetState(): ReviewsState {
      return { ...initialState };
    },
    setLoading(state, action: PayloadAction<boolean>): ReviewsState {
      return { ...state, loading: action.payload, }
    },
    setSubmitPending(state, action: PayloadAction<boolean>): ReviewsState {
      return { ...state, submitPending: action.payload, }
    },
    setCache(state, action: PayloadAction<DealerReview>): ReviewsState {
      const cache = {
        ...state.cache,
        [ action.payload.dealer ]: action.payload,
      }

      return {
        ...state,
        cache
      }
    },
    setListCache(state, action: PayloadAction<{ dealerId: string, reviews: DealerReview[] }>): ReviewsState {
      const listCache = {
        ...state.listCache,
        [ action.payload.dealerId ]: [
          ...action.payload.reviews
        ],
      }

      return {
        ...state,
        listCache,
      }
    },
    resetListCache(state, action: PayloadAction<string>): ReviewsState {
      const listCache = {
        ...state.listCache
      }

      delete listCache[ action.payload ];

      return {
        ...state,
        listCache,
      }
    },

  }
});


interface SubmitReviewArgs {
  id?: string;
  dealer: string;
  agent: string;
  offer: string;
  inquiry: string;
  rating: 1 | 2 | 3 | 4 | 5;
  review: string;
}


const submitReview = (args: SubmitReviewArgs): AppThunk => async (dispatch, getState) => {
  const { firebase, FieldValue } = await getFirebase();
  try {
    const user = getState().user.user;

    if (!user) {
      return;
    }

    dispatch(reviewService.actions.setSubmitPending(true));
    const { id, ...data } = args;
    if (id) {
      await firebase.firestore().collection('reviews').doc(id).update({
        status: 'pending',
        updateTime: FieldValue.serverTimestamp(),
        user: user.id,
        ...data,
      });
      dispatch(reviewService.actions.setCache({
        ...data,
        createTime: new Date().getTime(),
        updateTime: new Date().getTime(),
        id: id,
        user: user.id,
        userProfile: user as UserProfilePublic,
      }));
    }
    else {
      console.log({
        status: 'pending',
        createTime: FieldValue.serverTimestamp(),
        updateTime: FieldValue.serverTimestamp(),
        user: user.id,
        ...data,
      });
      const doc = await firebase.firestore().collection('reviews').add({
        status: 'pending',
        createTime: FieldValue.serverTimestamp(),
        updateTime: FieldValue.serverTimestamp(),
        user: user.id,
        ...data,
      });

      dispatch(reviewService.actions.setCache({
        ...data,
        createTime: new Date().getTime(),
        updateTime: new Date().getTime(),
        id: doc.id,
        user: user.id,
        userProfile: user as UserProfilePublic,
      }));
    }

    dispatch(reviewService.actions.resetListCache(args.dealer));
    return true;
  }
  catch (e) {
    dispatch(notification.show({ message: 'Error submitting a review', variant: 'error' }));
    console.error(e);
    return false;
  }
  finally {
    dispatch(reviewService.actions.setSubmitPending(false));
  }
}

const loadReview = (dealerId: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;

  if (!user) {
    return;
  }

  const cache = getState().reviews.cache;

  if (typeof cache[ dealerId ] !== 'undefined') {
    return;
  }

  try {
    const { firebase } = await getFirebase();
    const result = await firebase.firestore()
      .collection('reviews')
      .where('user', '==', user.id)
      .where('dealer', '==', dealerId)
      .where('status', 'in', [ 'active', 'pending' ])
      .limit(1)
      .get();

    if (result.size === 0) {
      return;
    }

    let review = result.docs[ 0 ].data() as DealerReview;
    review.id = result.docs[ 0 ].id;
    review = fixTimeStamps(review);
    dispatch(reviewService.actions.setCache(review));
  }
  catch (e) {
    console.error(e);
  }
}

const loadReviews = (dealerId: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user.user;

  if (!user) {
    return;
  }

  const cache = getState().reviews.listCache;

  if (typeof cache[ dealerId ] !== 'undefined') {
    return;
  }

  try {
    dispatch(reviewService.actions.setLoading(true));
    const { firebase } = await getFirebase();
    const result = await firebase.firestore()
      .collection('reviews')
      .where('user', '==', user.id)
      .where('dealer', '==', dealerId)
      .where('status', 'in', [ 'active', 'pending' ])
      .orderBy('updateTime', 'desc')
      .limit(50)
      .get();

    let reviews: DealerReview[] = [];

    const userRefs: firebase.firestore.DocumentReference[] = [];

    result.forEach(doc => {
      let review = doc.data() as DealerReview;
      review.id = doc.id;
      review = fixTimeStamps(review);
      reviews.push(review);
      userRefs.push(firebase.firestore().collection('users').doc(review.user).collection('public').doc('profile'));
    })

    reviews = await attachUsers(reviews, firebase.firestore());

    dispatch(reviewService.actions.setListCache({ dealerId, reviews }));
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(reviewService.actions.setLoading(false));
  }
}

export const actions = {
  submitReview,
  loadReview,
  loadReviews,
  reset: reviewService.actions.resetState,
};

export default reviewService.reducer;